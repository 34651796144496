export const FunFacts = [
    "My favorite food is Buffalo Chicken.",
    "My favorite game series is The Legend of Zelda.",
    "I discovered climbing through a friend after I expressed how boring going to the gym was.",
    "I grew up in Connecticut, home of the Whalers.",
    "I've driven across the United States 3 times so far.",
    "I'm an Eagle Scout.",
    "My favorite football team is the New York Giants.",
    "I'm not entirely fluent in German, but I have a strong proficiency in the language coming from a German family.",
    "I collect national park brochure maps.",
    "I've built my own epoxy river standing desk (check out my vlog of it on my Youtube).",
    "That's all, enjoy the rest of the game!",
];
